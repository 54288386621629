<template>
  <section class="bookPage">
    <main class="bookPage__main">
      <div class="asscroll-container">
        <div data-scroll>
          <ContentGrid :items="items" v-if="!isLiveYoutube" />
          <div v-else class="bookPage__live">
            <div style="--aspect-ratio:calc(16/9)">
              <youtube
                :video-id="youtube_parser(live_link)"
                :player-vars="{
                  autoplay: 1,
                  controls: 0,
                  modestbranding: 1,
                  cc_load_policy: 0,
                  rel: 0,
                  showinfo: 0,
                }"
                ref="youtube"
              />
            </div>
          </div>
          <PageFooter />
        </div>
      </div>
      <transition name="fade" appear>
        <ContentPopin v-if="isPopinOpen" />
      </transition>
    </main>
  </section>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import store from '../store/index'
import ContentGrid from '@/components/component/content-grid'
import ContentPopin from '@/components/component/content-popin'
import PageFooter from '@/components/component/page-footer'
import ASScroll from '@ashthornton/asscroll'
import Cookies from 'js-cookie'

// eslint-disable no-unused-vars
export default {
  components: {
    ContentGrid,
    ContentPopin,
    PageFooter,
  },
  async beforeRouteEnter(to, from, next) {
    const { version: currentVersion } = to.params
    const { publicVersions = [] } = store.state.configuration
    const publicVersionExists = publicVersions.find(
      (version) => version.slug === currentVersion
    )
    const isPublicVersionValid =
      publicVersionExists &&
      (await store.dispatch('authenticate', publicVersionExists.password))

    if (isPublicVersionValid || store.state.isUserLoggedIn) {
      return next()
    }

    return next('/')
  },
  data() {
    return {
      scroll: null,
    }
  },
  computed: {
    ...mapState({
      version: (state) => state.version,
      content: (state) => state.content,
      isPopinOpen: (state) => state.isPopinOpen,
      isMenuOpen: (state) => state.isMenuOpen,
      siteName: (state) => state.configuration.siteName,
      isLiveYoutube: (state) => JSON.parse(state.configuration.live),
      live_link: (state)     => state.configuration.live_link
    }),
    items() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
        ? this.version.gridMobile.items
        : this.version.gridDesktop.items
    },
  },
  watch: {
    isPopinOpen(value) {
      // this.toggleScroll(value || this.isMenuOpen);

      this.toggleScroll(value)
    },
    isMenuOpen(value) {
      if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
        // this.toggleScroll(value || this.isPopinOpen)
        this.toggleScroll(value)
      }
    },
  },
  created() {
    if (!Cookies.get('ga')) {
      const banner = document.getElementById('onetrust-banner-sdk')
      const accept = document.getElementById('onetrust-accept-btn-handler')
      const reject = document.getElementById('onetrust-reject-all-handler')
      reject.style.display = 'block !important'
      banner.style.display = 'unset'
      accept.addEventListener(
        'click',
        () => {
          // this.$ga.enable();
          banner.style.transform = 'translateY(100%)'
          Cookies.set('ga', '1')
        },
        false
      )
    } else {
      // this.$ga.enable();
    }

    this.setTheme('light')
    this.setShowHeader(true)
    this.$bus.$on('scrollToTop', () => {
      if (this.scroll instanceof ASScroll) {
        this.scroll.scrollTo(0)
      }
    })
  },
  mounted() {
    window.setTimeout(() => {
        window.scrollTo(0,0)
      }, 100)
    document.title =
      this.siteName.split('<br />').join(' ').toUpperCase() + ' - Saint Laurent'
    this.$nextTick(() => {
      if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) return
      this.scroll = new ASScroll({
        ease: 0.1,
      })
      this.scroll.enable()
    })

  },
  methods: {
    youtube_parser(url){
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      return (match&&match[7].length==11)? match[7] : false;
    },
    ...mapMutations({
      setTheme: 'setTheme',
      setShowHeader: 'setShowHeader',
    }),
    toggleScroll(disable = false) {
      if (disable) {
        if (this.scroll) this.scroll.disable()
        else document.body.style.overflow = 'hidden'
      } else {
        if (this.scroll) this.scroll.enable()
        else document.body.style.overflow = 'auto'
      }
    },
  },
}
</script>

<style lang="scss">
.bookPage {
  &,
  &__main {
    display: block;
  }

  &__main {
    background-color: var(--white);
    color: var(--black);
  }
  .asscroll-container > div {
  }

  .asscroll-container > div {
    min-height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 768px) {
      height: 100%;
      min-height: unset;
    }
  }

  &__live {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
      background-color: var(--black);
      height: calc(100vh - 50px);
    }

    > div {
      width: 60%;

      @media screen and (max-height: 650px) {
        width: 50%;
      }

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}
</style>
